require("slick-carousel")

import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"

$('.testimony-slick').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    dots: true,
    arrows: true,

    responsive: [
    {
        breakpoint: 1366,
        settings: {
        arrows: false,
        }
    }]
});
